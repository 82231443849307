<template>
    <div class="container login">
        <h1>Anmeldung</h1>
        <form>
            <div class="toast toast-error" v-show="failure">Falsches Passwort</div>
            <div class="form-group">
                <label class="form-label" for="input-example-1">Passwort</label>
                <input class="form-input" type="password" id="pwd" placeholder="" v-model="pwd" />
            </div>
            <button class="btn btn-primary" @click.prevent="login()">Login</button>
        </form>
    </div>
</template>

<script>
import Vue from "vue";
import Vuex from "vuex";
import store from "../store/vuex-store";

export default {
    data: () => ({
        pwd: '',
        failure: false,
    }),
    methods: {

        login() {
            this.failure = false;
            var headers = {
                'Content-Type': 'application/json',
            }
            this.$http.post(Vue.config.api_url + '/login', { pwd: this.pwd }, {headers}).then(r => {
                if(r.body.code == 0) {
                    store(Vuex).commit("login", {token: r.body.data.token});
                    this.$router.push('/images/' + window.productid);
                } else {
                    this.failure = true;
                }
            }).catch(error => {console.log(error); this.failure = true;});
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    max-width: 400px;
}
form {
    border: 1px solid #777;
    padding: 15px;
    border-radius: 7px;
    box-shadow: 3px 3px 3px grey;
}
</style>
